import React, { useEffect, useState } from 'react'
import { useParams, useHistory, Link, NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { InfoIcon, useErrorService, PrintableArea } from '@abroad/components'
import { SurveyResult as Result } from '@abroad/components/dist/survey'
import { useUserFirstName } from '../components'
import API from '../utils/API'
import i18n from '../utils/i18n'

export const ClientSurveyResult = () => {
  const { surveyId, clientId } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  const [survey, setSurvey] = useState(null)
  let history = useHistory()
  const Error = useErrorService()
  const userFirstName = useUserFirstName(clientId)

  useEffect(() => {
    const getSurveys = async () => {
      try {
        const { data } = await API.CoachAPI.coachClient.getUserSurveyResult(
          surveyId,
          clientId,
        )
        if (data) {
          setSurvey(data)
        }
        setIsLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else {
          setIsLoading(false)
          Error.showError(e)
        }
      }
    }
    if (surveyId) {
      getSurveys()
    } else {
      history.replace('/admin/home')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{`${userFirstName} Survey Result | Abroad`}</title>
        <meta
          name='title'
          content={`${userFirstName} Survey Result | Abroad`}></meta>
        <meta
          property='og:title'
          content={`${userFirstName} Survey Result | Abroad`}></meta>
        <meta
          property='twitter:title'
          content={`${userFirstName} Survey Result | Abroad`}></meta>
        <link
          rel='canonical'
          href={`${process.env.REACT_APP_DOMAIN}/survey/view/${surveyId}`}></link>
        <meta
          property='og:url'
          content={`${process.env.REACT_APP_DOMAIN}/survey/view/${surveyId}`}></meta>
        <meta
          property='twitter:url'
          content={`${process.env.REACT_APP_DOMAIN}/survey/view/${surveyId}`}></meta>
      </Helmet>
      <main>
        <Result
          survey={survey}
          isLoading={isLoading}
          i18n={i18n}
          clientId={clientId}
          navigateToSurveyCategory={(subcategory) => {
            return (
              <Link to={`/survey/category/${subcategory}`}>
                <InfoIcon />
              </Link>
            )
          }}
          headerText='Assessment Results'
          showBreakthroughProgramInfo={false}
          onGoBack={() => history.goBack()}
          showGoBackButton={false}
          API={API}
          NavLink={NavLink}
        />
      </main>
      <PrintableArea printSelector='main' />
    </>
  )
}
