import React, { useEffect, useRef, useState } from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'
import LearnMore from './LearnMore'
import GaugeChart from './GaugeChart'
import CategoryModal from './CategoryModal'
import RecommendedAudio from './RecommendedAudio'
import ABTooltip from '../Tooltip'
import ABSpinner from '../Spinner/index'
import ModalProvider from '../common/ModalProvider'
import useErrorService from '../../services/ErrorService'

const SurveyResult = ({
  isLoading,
  survey,
  clientId,
  onClick,
  i18n,
  navigateToSurveyCategory = () => {},
  onLearnMoreClick = () => {},
  headerText,
  subHeaderText = '',
  subHeaderDescription = '',
  showBreakthroughProgramInfo = true,
  onGoBack = () => {},
  showGoBackButton = true,
  API,
  NavLink,
  playRecommendedAudio = () => {}
}) => {
  const Error = useErrorService()
  const recommendedSwiperRef = useRef(null)
  const [recommendedData, setRecommendedData] = useState([])
  const [isRecommendedLoading, setIsRecommendedLoading] = useState(false)
  const history = useHistory()
  const [recommendPage, setRecommendPage] = useState(1)
  const limit = 6

  useEffect(() => {
    const getRecommendedData = async () => {
      try {
        if (recommendPage === 1) {
          setIsRecommendedLoading(true)
        }
        const { data } = await API.mediaLibrary.getRecommendedMedias({
          page: recommendPage,
          limit
        })
        if (Array.isArray(data)) {
          setRecommendedData([...recommendedData, ...data])
        } else {
          throw data
        }
        setIsRecommendedLoading(false)
      } catch (e) {
        if (
          e?.code === 'ValidationError' ||
          e?.code === 'resource_not_found' ||
          e?.code === 'not_found'
        ) {
          history.replace('/404')
        } else {
          setIsRecommendedLoading(false)
          Error.showError(e)
        }
      }
    }
    if (showBreakthroughProgramInfo) getRecommendedData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendPage])

  useEffect(() => {
    if (!isRecommendedLoading && recommendedData.length > 0) {
      setTimeout(() => {
        if (recommendedSwiperRef && recommendedSwiperRef.current)
          recommendedSwiperRef.current.update()
      })
    }
  }, [isRecommendedLoading, recommendedData])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <div className='abroad-fs-exclude'>
      <ModalProvider>
        <section>
          <Container
            className={classNames('pt-4 mb-2-5', {
              'custom-offset-lg-1 custom-col-lg-12 px-lg-0':
                !showBreakthroughProgramInfo
            })}
            fluid
          >
            <Row className='mb-3'>
              <Col className='text-center'>
                <div className='d-flex justify-content-center align-items-center'>
                  {showGoBackButton && (
                    <div className='back-btn mr-lg-4 mr-2'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='36'
                        viewBox='0 0 20 36'
                        fill='none'
                      >
                        <path
                          d='M18.5748 1.35352L1.4248 17.9997'
                          stroke='black'
                          strokeWidth='2.5'
                          strokeLinecap='round'
                        />
                        <path
                          d='M18.5748 34.6462L1.4248 18'
                          stroke='black'
                          strokeWidth='2.5'
                          strokeLinecap='round'
                        />
                      </svg>

                      <svg
                        onClick={onGoBack}
                        className='cursor-pointer'
                        width='20'
                        height='36'
                        viewBox='0 0 22 38'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M1.5131 20.4459C0.68878 19.6582 0.688778 18.3418 1.5131 17.5541L18.3682 1.44705C19.6407 0.231097 21.75 1.13298 21.75 2.89299L21.75 35.107C21.75 36.867 19.6407 37.7689 18.3682 36.553L1.5131 20.4459Z'
                          fill='black'
                        />
                      </svg>
                    </div>
                  )}
                  <div className='d-flex'>
                    <h2 className='font-montserrat font-bold font-32 text-dark-900 line-height-39 letter-spacing-minus-02 mb-0'>
                      {headerText}
                    </h2>
                  </div>
                </div>
                {subHeaderText && (
                  <React.Fragment>
                    <h4 className='font-montserrat font-semibold text-grey-600 mt-3'>
                      {subHeaderText}
                    </h4>
                    <p className='font-montserrat text-grey-600 d-flex justify-content-center mt-3'>
                      <span className='w-100 w-lg-7/12'>
                        {subHeaderDescription}
                      </span>
                    </p>
                  </React.Fragment>
                )}
              </Col>
            </Row>
          </Container>
        </section>
        <section>
          <Container className='pb-4 category-chart-section' fluid>
            <div
              className={classNames({
                'custom-offset-lg-1 custom-col-lg-12 px-lg-0':
                  !showBreakthroughProgramInfo,
                'custom-col-lg-8 custom-col-10 custom-offset-lg-1':
                  showBreakthroughProgramInfo
              })}
            >
              <Row xs={1} sm={1} md={1} lg={3} xl={3}>
                <Col className='position-relative text-center'>
                  <div className='well text-center bg-blue-100 category-score-card'>
                    <GaugeChart
                      backgroundColor='#e5e5e5'
                      color='#ffffff'
                      percentage={survey?.results?.self_awareness?.percent}
                      labelProps={{
                        scoreClassName: 'category-score',
                        percentageClassName: 'seperate-percentage'
                      }}
                    />
                    <label className='category-title-label'>
                      Self Awareness
                    </label>
                    <ABTooltip
                      placement='auto-end'
                      controllId='xs-awareness-tooltip'
                      contentText='Now that you have completed your assessment you can quickly see what areas could benefit from growth and development.'
                      className='d-flex d-lg-none xs-awareness-tooltip-icon'
                    />
                  </div>
                  <LearnMore
                    i18n={i18n}
                    category={
                      survey?.categories.self_awareness ||
                      survey?.categories?.find(
                        (subCat) => subCat?.key === 'self_awareness'
                      )
                    }
                    onLearnMoreClick={onLearnMoreClick}
                  />
                </Col>
                <Col className='text-center'>
                  <div className='position-relative well text-center bg-green-100 category-score-card'>
                    <ABTooltip
                      controllId='assessment-tooltip'
                      contentText='Now that you have completed your assessment you can quickly see what areas could benefit from growth and development.'
                      className='d-none d-lg-flex team-cohesion-tooltip-icon'
                    />
                    <GaugeChart
                      backgroundColor='#e5e5e5'
                      color='#ffffff'
                      percentage={
                        survey?.inTransition
                          ? null
                          : survey?.results?.team_cohesion?.percent
                      }
                      labelProps={{
                        scoreClassName: 'category-score',
                        percentageClassName: 'seperate-percentage'
                      }}
                    />
                    <label className='category-title-label'>
                      Team Cohesion
                    </label>
                  </div>
                  <LearnMore
                    category={
                      survey?.categories.team_cohesion ||
                      survey?.categories?.find(
                        (subCat) => subCat?.key === 'team_cohesion'
                      )
                    }
                    onLearnMoreClick={onLearnMoreClick}
                    i18n={i18n}
                  />
                </Col>
                <Col className='text-center'>
                  <div className='well text-center bg-red-500 category-score-card'>
                    <GaugeChart
                      backgroundColor='#e5e5e5'
                      color='#ffffff'
                      percentage={
                        survey?.inTransition
                          ? null
                          : survey?.results?.vision__impact?.percent
                      }
                      labelProps={{
                        scoreClassName: 'category-score',
                        percentageClassName: 'seperate-percentage'
                      }}
                    />
                    <label className='category-title-label'>
                      Vision & Impact
                    </label>
                  </div>
                  <LearnMore
                    category={
                      survey?.categories.vision__impact ||
                      survey?.categories?.find(
                        (subCat) => subCat?.key === 'vision__impact'
                      )
                    }
                    onLearnMoreClick={onLearnMoreClick}
                    i18n={i18n}
                  />
                </Col>
              </Row>
            </div>
          </Container>
        </section>
        <section className='bg-gray-104 container-fluid'>
          <div
            className={classNames('', {
              'custom-offset-lg-1 custom-col-lg-12 px-lg-0':
                !showBreakthroughProgramInfo,
              'custom-col-lg-8 custom-col-10 custom-offset-lg-1':
                showBreakthroughProgramInfo
            })}
          >
            <Row
              className={classNames('justify-content-center', {
                'pl-2': !showBreakthroughProgramInfo
              })}
            >
              <Col
                className={classNames(
                  'd-flex flex-column mt-3-5 mb-3-5 col-12 col-lg-11 px-md-30',
                  {
                    'pl-lg-3': !showBreakthroughProgramInfo
                  }
                )}
              >
                <span className='explore-insight-title font-bold leading-9 mb-3'>
                  Interpreting Results
                </span>
                <p className='explore-insight-description font-semibold leading-7 letter-spacing-minus-02'>
                  Below we have further broken down your assessment results. To
                  learn more about each subcategory, click the info buttons. To
                  better understand your results, we highly recommend you{' '}
                  <a
                    className='text-saffron-700'
                    href='mailto:service@abroad.io?subject=Schedule a growth plan session with an Abroad coach.'
                  >
                    schedule a session
                  </a>{' '}
                  with one of our coaches who will ask further questions to
                  uncover the assumptions, mindsets and behaviors driving your
                  results with the goal of generating a targeted growth plan.
                </p>
              </Col>
            </Row>
          </div>
        </section>
        <section>
          <div
            className={classNames('', {
              'custom-offset-lg-1 custom-col-lg-12 px-lg-0':
                !showBreakthroughProgramInfo,
              'custom-col-lg-8 custom-col-10 custom-offset-lg-1':
                showBreakthroughProgramInfo
            })}
          >
            <div className='mt-3-5 mb-3-5 font-open-sans'>
              <div className='d-flex flex-column flex-lg-row justify-content-center align-items-center'>
                <div className='assessment-circle text-center'>
                  <GaugeChart
                    backgroundColor='#e5e5e5'
                    color='#69B1D8'
                    percentage={survey?.results?.self_awareness?.percent}
                    className='score'
                    size='85%'
                    labelProps={{
                      scoreClassName: 'category-total-score text-blue-100',
                      percentageClassName: 'percentage-icon text-blue-100'
                    }}
                  />
                  <div className='text-blue-100 font-semibold leading-7-half letter-spacing-minus-02 category-assessment-label'>
                    Self Awareness
                  </div>
                  <LearnMore
                    category={
                      survey?.categories.self_awareness ||
                      survey?.categories?.find(
                        (subCat) => subCat?.key === 'self_awareness'
                      )
                    }
                    onLearnMoreClick={onLearnMoreClick}
                    i18n={i18n}
                  />
                </div>
                <div className='assessment-score'>
                  <div className='self-awareness-courses-wrp'>
                    <div className='position-relative self-awareness-courses-lable leading-5 letter-spacing-2 text-uppercase font-montserrat text-blue-100'>
                      Key Aspects of Self Awareness
                      <ABTooltip
                        controllId='Key Aspects of Self Awareness'
                        contentText="Clicking the 'Information buttons' next to any subcategory will provide you instant access to a video and write-up to understand your results."
                        className='d-none d-lg-flex self-awareness-tooltip-icon'
                      />
                      <ABTooltip
                        placement='auto-end'
                        controllId='Key Aspects of Self Awareness'
                        contentText="Clicking the 'Information buttons' next to any subcategory will provide you instant access to a video and write-up to understand your results."
                        className='d-flex d-lg-none self-awareness-tooltip-icon'
                      />
                    </div>
                    <div className='self-awareness-courses-title-wrp'>
                      {survey?.results?.self_awareness?.order.map(
                        (subcategory) => (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a
                            key={
                              survey?.results?.self_awareness?.subcategories[
                                subcategory
                              ]?.title
                            }
                            className='survey-subcat-link'
                          >
                            <div
                              className='self-awareness-courses-title-row'
                              data-id={
                                survey?.results?.self_awareness?.subcategories[
                                  subcategory
                                ]?.title
                              }
                            >
                              <div className='courses-title'>
                                <span className='course-title-span font-semibold font-open-sans leading-6 letter-spacing-minus-02'>
                                  {
                                    survey?.results?.self_awareness
                                      ?.subcategories[subcategory]?.title
                                  }
                                </span>
                              </div>
                              <div className='d-flex'>
                                <div className='courses-score font-semibold font-open-sans leading-6 letter-spacing-minus-02 mr-3 ms-3 align-self-center'>
                                  {Number.isInteger(
                                    survey?.results?.self_awareness
                                      ?.subcategories[subcategory]?.percent
                                  )
                                    ? `${survey?.results?.self_awareness?.subcategories[subcategory]?.percent}%`
                                    : 'N/A'}
                                </div>
                                {navigateToSurveyCategory(subcategory)}
                              </div>
                            </div>
                          </a>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className='py-3-5 bg-gray-104 font-open-sans'>
            <div
              className={classNames('', {
                'custom-offset-lg-1 custom-col-lg-12 px-lg-0':
                  !showBreakthroughProgramInfo,
                'custom-col-lg-8 custom-col-10 custom-offset-lg-1':
                  showBreakthroughProgramInfo
              })}
            >
              <div className='d-flex flex-column flex-lg-row justify-content-center align-items-center'>
                <div className='assessment-circle text-center'>
                  <GaugeChart
                    backgroundColor='#e5e5e5'
                    color='#509f92'
                    percentage={
                      survey?.inTransition
                        ? null
                        : survey?.results?.team_cohesion?.percent
                    }
                    className='score'
                    size='85%'
                    labelProps={{
                      scoreClassName: 'category-total-score text-green-100',
                      percentageClassName: 'percentage-icon text-green-100'
                    }}
                  />
                  <div className='text-green-100 font-semibold leading-7-half letter-spacing-minus-02 category-assessment-label'>
                    Team Cohesion
                  </div>
                  <LearnMore
                    category={
                      survey?.categories.team_cohesion ||
                      survey?.categories?.find(
                        (subCat) => subCat?.key === 'team_cohesion'
                      )
                    }
                    onLearnMoreClick={onLearnMoreClick}
                    i18n={i18n}
                  />
                </div>
                <div className='assessment-score'>
                  <div className='self-awareness-courses-wrp'>
                    <div className='self-awareness-courses-lable leading-5 letter-spacing-2 text-uppercase font-montserrat text-green-100'>
                      Key Aspects of Team Cohesion
                    </div>
                    <div className='self-awareness-courses-title-wrp'>
                      {survey?.results?.team_cohesion?.order.map(
                        (subcategory) => (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a
                            key={
                              survey?.results?.team_cohesion?.subcategories[
                                subcategory
                              ]?.title
                            }
                            className='survey-subcat-link'
                          >
                            <div
                              className='self-awareness-courses-title-row'
                              data-id={
                                survey?.results?.team_cohesion?.subcategories[
                                  subcategory
                                ]?.title
                              }
                            >
                              <div className='courses-title'>
                                <span className='course-title-span font-semibold font-open-sans leading-6 letter-spacing-minus-02'>
                                  {
                                    survey?.results?.team_cohesion
                                      ?.subcategories[subcategory]?.title
                                  }
                                </span>
                              </div>
                              <div className='d-flex'>
                                <div className='courses-score font-semibold font-open-sans leading-6 letter-spacing-minus-02 mr-3 ms-3 align-self-center'>
                                  {survey?.inTransition
                                    ? 'N/A'
                                    : Number.isInteger(
                                        survey?.results?.team_cohesion
                                          ?.subcategories[subcategory]?.percent
                                      )
                                    ? `${survey?.results?.team_cohesion?.subcategories[subcategory]?.percent}%`
                                    : 'N/A'}
                                </div>
                                {navigateToSurveyCategory(subcategory)}
                              </div>
                            </div>
                          </a>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            className={classNames('', {
              'custom-offset-lg-1 custom-col-lg-12 px-lg-0':
                !showBreakthroughProgramInfo,
              'custom-col-lg-8 custom-col-10 custom-offset-lg-1':
                showBreakthroughProgramInfo
            })}
          >
            <div className='mt-3-5 mb-3-5 font-open-sans'>
              <div className='d-flex flex-column flex-lg-row justify-content-center align-items-center'>
                <div className='assessment-circle text-center'>
                  <GaugeChart
                    backgroundColor='#e5e5e5'
                    color='#BC4C6A'
                    percentage={
                      survey?.inTransition
                        ? null
                        : survey?.results?.vision__impact?.percent
                    }
                    className='score'
                    size='85%'
                    labelProps={{
                      scoreClassName: 'category-total-score text-red-500',
                      percentageClassName: 'percentage-icon text-red-500'
                    }}
                  />
                  <div className='text-red-500 font-semibold leading-7-half letter-spacing-minus-02 category-assessment-label'>
                    Vision & Impact
                  </div>
                  <LearnMore
                    category={
                      survey?.categories.vision__impact ||
                      survey?.categories?.find(
                        (subCat) => subCat?.key === 'vision__impact'
                      )
                    }
                    onLearnMoreClick={onLearnMoreClick}
                    i18n={i18n}
                  />
                </div>
                <div className='assessment-score'>
                  <div className='self-awareness-courses-wrp'>
                    <div className='self-awareness-courses-lable leading-5 letter-spacing-2 text-uppercase font-montserrat text-red-500'>
                      Key Aspects of Vision & Impact
                    </div>
                    <div className='self-awareness-courses-title-wrp'>
                      {survey?.results?.vision__impact?.order.map(
                        (subcategory) => (
                          // eslint-disable-next-line jsx-a11y/anchor-is-valid
                          <a
                            key={
                              survey?.results?.vision__impact?.subcategories[
                                subcategory
                              ]?.title
                            }
                            className='survey-subcat-link'
                          >
                            <div
                              className='self-awareness-courses-title-row'
                              data-id={
                                survey?.results?.vision__impact?.subcategories[
                                  subcategory
                                ]?.title
                              }
                            >
                              <div className='courses-title'>
                                <span className='course-title-span font-semibold font-open-sans leading-6 letter-spacing-minus-02'>
                                  {
                                    survey?.results?.vision__impact
                                      ?.subcategories[subcategory]?.title
                                  }
                                </span>
                              </div>
                              <div className='d-flex'>
                                <div className='courses-score font-semibold font-open-sans leading-6 letter-spacing-minus-02 mr-3 ms-3 align-self-center'>
                                  {survey?.inTransition
                                    ? 'N/A'
                                    : Number.isInteger(
                                        survey?.results?.vision__impact
                                          ?.subcategories[subcategory]?.percent
                                      )
                                    ? `${survey?.results?.vision__impact?.subcategories[subcategory]?.percent}%`
                                    : 'N/A'}
                                </div>
                                {navigateToSurveyCategory(subcategory)}
                              </div>
                            </div>
                          </a>
                        )
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            className={classNames('', {
              'custom-offset-lg-1 custom-col-lg-12 px-lg-3 px-md-5 px-sm-5':
                !showBreakthroughProgramInfo,
              'custom-col-lg-8 custom-col-10 custom-offset-lg-1':
                showBreakthroughProgramInfo
            })}
          >
            {clientId ? (
              <>
                <Row className='mb-3'>
                  <Col>
                    <h3 className='s2 mb-0'>Assessment User</h3>
                  </Col>
                </Row>
                <Table hover responsive className='custom-table'>
                  <thead>
                    <tr>
                      <th width='20%'>Full Name</th>
                      <th width='20%'>Survey Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{survey.fullName}</td>
                      <td className='text-capitalize'>{survey.surveyType}</td>
                    </tr>
                  </tbody>
                </Table>
              </>
            ) : (
              <>
                <Row className='mb-3'>
                  <Col>
                    <h3 className='s2 mb-0'>Assessment Users</h3>
                  </Col>
                </Row>
                <Table hover responsive className='custom-table'>
                  <thead>
                    <tr>
                      <th width='5%'>#</th>
                      <th width='10%'>Full Name</th>
                      <th width='20%'>Email</th>
                    </tr>
                  </thead>
                  <tbody>
                    {survey?.users && survey?.users?.length > 0 ? (
                      survey?.users.map((user, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{user.fullName}</td>
                          <td>{user.email}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan='3' className='text-center'>
                          Assessment users not available at this moment.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </>
            )}
          </div>
        </section>
        {showBreakthroughProgramInfo === false &&
        recommendedData?.length === 0 ? null : (
          <section className='bg-gray-104'>
            <div
              className={classNames('', {
                'custom-offset-lg-1 custom-col-lg-12 px-lg-0':
                  !showBreakthroughProgramInfo,
                'custom-col-lg-8 custom-col-10 custom-offset-lg-1':
                  showBreakthroughProgramInfo
              })}
            >
              <Row className='justify-content-center recommended-audios'>
                <Col className='col-12 col-lg-11 px-lg-4 px-075'>
                  <h2 className='font-open-sans font-18pt font-bold leading-9 letter-spacing-minus-04 mb-2 px-lg-0'>
                    Recommended for You
                  </h2>
                  <p className='font-open-sans font-12pt font-semibold leading-7 letter-spacing-minus-02 mb-2-5 px-lg-0'>
                    The Breakthrough Program has been scientifically-evidenced
                    to create breakthrough results for leaders globally, and is
                    the same program featured in our landmark global.
                  </p>
                  <RecommendedAudio
                    NavLink={NavLink}
                    recommendedData={recommendedData}
                    isRecommendedLoading={isRecommendedLoading}
                    limit={limit}
                    setRecommendPage={setRecommendPage}
                    recommendedSwiperRef={recommendedSwiperRef}
                    playRecommendedAudio={playRecommendedAudio}
                  />
                </Col>
              </Row>
            </div>
          </section>
        )}
        <CategoryModal i18n={i18n} />
      </ModalProvider>
    </div>
  )
}

export default SurveyResult
