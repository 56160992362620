import React from 'react'
import { Col, Row } from 'react-bootstrap'
import {
  ShimmerThumbnail,
  ShimmerText,
  ShimmerTable,
  ShimmerTitle,
  ShimmerButton
} from 'react-shimmer-effects'

const AssessmentComparisionLoader = () => {
  return (
    <>
      <Row>
        <Col sm={4} className='mt-5'>
          <ShimmerThumbnail height={50} />
        </Col>
      </Row>
      <Row>
        <Col>
          <ShimmerText line={7} gap={20} />
        </Col>
      </Row>

      {Array.from({ length: 3 }, (_) => (
        <>
          <Row>
            <Col sm={5} className='mt-2'>
              <ShimmerThumbnail width={250} height={50} />
            </Col>
          </Row>

          <Row>
            <Col
              sm={12}
              md={12}
              lg={4}
              xl={3}
              className='mt-2 compare-thumbnail-responsive'
            >
              <ShimmerThumbnail />
            </Col>
            <Col
              sm={12}
              md={12}
              lg={8}
              xl={9}
              className='mt-2 compared-thumbnail-responsive'
            >
              <ShimmerThumbnail />
              <ShimmerTable row={3} col={3} />
            </Col>
          </Row>
        </>
      ))}

      <Row className='d-flex justify-content-center mt-5'>
        <Col sm={9} lg={9} xl={6}>
          <ShimmerTitle line={1} />
        </Col>
      </Row>
      <Row className='d-flex justify-content-center'>
        <Col sm={11} lg={11} xl={8}>
          <ShimmerTitle line={1} />
        </Col>
      </Row>
      <Row>
        <Col className='d-flex justify-content-center'>
          <ShimmerButton size='sm' />
        </Col>
      </Row>
    </>
  )
}

export default AssessmentComparisionLoader
