import React, { useState } from 'react'
import { useErrorService } from '@abroad/components'
import API from '../../utils/API'
import { formatDate, getCurrentDate } from '../../utils/date'
import ExportCSV from '../../utils/ExportCSV'

const ExportPaymentUser = ({ hasPaymentsData, reqData }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [exportData, setExportData] = useState([])
  const Error = useErrorService()
  const currentDate = formatDate(getCurrentDate())
  const filename = `user_payment_${currentDate}.csv`

  const handleExportUser = async () => {
    setIsLoading(true)

    const params = {
      filter: reqData.filter,
    }

    Object.keys(params).forEach(
      (key) => params[key] === '' && delete params[key],
    )

    try {
      const { data: paymentUserData } =
        await API.AdminAPI.payment.exportPaymentUsersCSV(params)
      if (paymentUserData) {
        setExportData(paymentUserData)
        document.getElementById('EXPORT_PAYMENT_USER').click()
        setIsLoading(false)
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  return (
    <ExportCSV
      isLoading={isLoading}
      hasData={hasPaymentsData}
      exportData={exportData}
      buttonName='EXPORT'
      csvId='EXPORT_PAYMENT_USER'
      filename={filename}
      handleExportUser={handleExportUser}
    />
  )
}

export default ExportPaymentUser
