import React from 'react'
import { Col, Row } from 'react-bootstrap'
import {
  ShimmerButton,
  ShimmerText,
  ShimmerThumbnail
} from 'react-shimmer-effects'

const AssessmentLoader = ({ isWisdom = false }) => {
  const ShimmerEffect = () => {
    return (
      <Row className='my-3'>
        <Col sm={11}>
          <ShimmerText line={1} gap={20} className='shimmer-text-height' />
        </Col>
        <Col sm={1}>
          <ShimmerText line={1} gap={20} className='shimmer-text-height' />
        </Col>
      </Row>
    )
  }

  return (
    <>
      <Row>
        <Col sm={5}>
          <ShimmerButton size='md' />
        </Col>
      </Row>
      {isWisdom ? (
        <Row>
          <Col sm={12} lg={6}>
            <div className='d-flex justify-content-center'>
              <ShimmerButton size='md' />
            </div>
            <div className='center'>
              <ShimmerThumbnail height={250} width={300} />
            </div>
            <div className='d-flex justify-content-center'>
              <ShimmerButton size='sm' />
            </div>
          </Col>
          <Col sm={12} lg={3} className='mt-5 mt-lg-0'>
            <div className='d-flex justify-content-center'>
              <ShimmerButton size='md' />
            </div>
            <div className='center'>
              <ShimmerThumbnail height={250} width={300} />
            </div>
            <div className='d-flex justify-content-center'>
              <ShimmerButton size='sm' />
            </div>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col sm={12} lg={4}>
            <div className='d-flex justify-content-center'>
              <ShimmerButton size='md' />
            </div>
            <div className='center'>
              <ShimmerThumbnail height={250} width={300} />
            </div>
            <div className='d-flex justify-content-center'>
              <ShimmerButton size='sm' />
            </div>
          </Col>
          <Col sm={12} lg={4} className='mt-5 mt-lg-0'>
            <div className='d-flex justify-content-center'>
              <ShimmerButton size='md' />
            </div>
            <div className='center'>
              <ShimmerThumbnail height={250} width={300} />
            </div>
            <div className='d-flex justify-content-center'>
              <ShimmerButton size='sm' />
            </div>
          </Col>
          <Col sm={12} lg={4} className='mt-5 mt-lg-0'>
            <div className='d-flex justify-content-center'>
              <ShimmerButton size='md' />
            </div>
            <div className='center'>
              <ShimmerThumbnail height={250} width={300} />
            </div>
            <div className='d-flex justify-content-center'>
              <ShimmerButton size='sm' />
            </div>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm={12}>
          <ShimmerText className='shimmer-text-height mt-2' />
        </Col>
      </Row>
      {Array.from({ length: isWisdom ? 2 : 3 }, (_, index) => (
        <Row key={index}>
          <Col sm={12} lg={4}>
            <div className='d-flex justify-content-sm-center'>
              <ShimmerThumbnail height={450} width={250} />
            </div>
          </Col>
          <Col sm={12} lg={8} className='mt-4'>
            <ShimmerButton size='md' />
            <ShimmerEffect />
            <ShimmerEffect />
            <ShimmerEffect />
            <ShimmerEffect />
            <ShimmerEffect />
            <ShimmerEffect />
          </Col>
        </Row>
      ))}
      {isWisdom ? (
        <Row>
          <Col sm={12} lg={6}>
            <ShimmerThumbnail height={250} width={470} />
          </Col>
          <Col sm={12} lg={6}>
            <ShimmerThumbnail height={250} width={470} />
          </Col>
        </Row>
      ) : (
        ' '
      )}
    </>
  )
}
export default AssessmentLoader
