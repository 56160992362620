import React from 'react'
import { CSVLink } from 'react-csv'
import classNames from 'classnames'
import { Button } from '@abroad/components'

const ExportCSV = ({
  isLoading,
  hasData,
  headers,
  csvId,
  exportData,
  buttonName,
  filename,
  handleExportUser,
}) => {
  if (!hasData) return null

  return (
    <div className='d-flex'>
      <Button
        variant='gray'
        className={classNames('line-height-normal border-radius-3', {
          'd-none': !hasData,
          'ml-2': hasData,
        })}
        isLoading={isLoading}
        disabled={isLoading || !hasData}
        onClick={() => handleExportUser()}>
        {buttonName}
      </Button>
      <CSVLink
        data={exportData}
        className='d-none'
        id={csvId}
        filename={filename}
        headers={headers}>
        {buttonName}
      </CSVLink>
    </div>
  )
}

export default ExportCSV
