import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Button, ABSpinner } from '@abroad/components'
import { useHistory, useParams, useLocation } from 'react-router-dom'
import API from '../utils/API'
import Custom360QuesField from '../components/custom360/Custom360QuesField'
import Custom360SendReq from '../components/custom360/Custom360SendReq'

const Custom360 = ({ clientProfile }) => {
  const history = useHistory()
  const { clientId } = useParams()
  const [canViewResult, setCanViewResult] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const location = useLocation()
  const [dataQues, setDataQues] = useState('')

  const receiveQuesLength = (questionData) => {
    setDataQues(questionData)
  }
  const isUserComeFromMyClients = location?.pathname
    .split('/')
    .includes('coach')

  useEffect(() => {
    const getAnswerStatus = async () => {
      setIsLoading(true)
      try {
        const { data } = await API.AdminAPI.survey.getAnsStatus(clientId)
        setCanViewResult(data?.status)
        setIsLoading(false)
      } catch {}
    }
    getAnswerStatus()
  }, [clientId])

  if (isLoading) {
    return <ABSpinner />
  }

  return (
    <>
      <div>
        {canViewResult && (
          <Button
            variant='gray'
            className='px-4 py-3 abroad-blue mt-5'
            type='button'
            onClick={() => {
              history.push(
                isUserComeFromMyClients
                  ? `/coach/custom360/clients/${clientId}/results/question`
                  : `/admin/custom360/users/${clientId}/results/question`,
              )
            }}>
            VIEW PREVIOUS RESULTS &nbsp; {nextIcon()}
          </Button>
        )}
        <Row className='mt-40'>
          <Col lg={6}>
            <h1 className='sa1'>Questions & Recipients</h1>
            <p className='mt-3 sa2'>
              Welcome to the Custom 360 Feedback tool. Here, you can input up to
              10 personalized questions and select up to 10 recipients for
              providing feedback on your client’s development. Ensure the
              questions are clear and focus on key developmental areas. Use ‘ADD
              NUMERICAL’ for questions intended to be answered on a 1-6 scale,
              and ‘ADD WRITTEN’ for those requiring detailed written responses.
              After adding the recipients’ names and email addresses, review
              your entries for accuracy before sending. The recipients will be
              invited via email to confidentially provide their feedback on our
              platform. Remember, the effectiveness of this feedback relies on
              the precision and relevance of your questions, so craft them to
              elicit insightful and actionable responses.
            </p>
            <p className='sa2 text-saffron-600'>
              Use @firstname to add first name of the user in the question. I.e.
              “What is your relationship with @firstname?” Receiver will see it
              as “What is your relationship with {clientProfile?.fname}”.
            </p>
            <Custom360QuesField
              viewResult={canViewResult}
              setQuestionLength={receiveQuesLength}
            />
            <div>
              <Custom360SendReq questionLength={dataQues} />
            </div>
          </Col>
          <Col lg={6}>
            <div className='d-flex justify-content-between'>
              <h1 className='sa1'>Email Copy</h1>
            </div>
            <div className='preview-email mt-3'>
              <span className='font-weight-bold'> Dear [Firstname],</span>
              <br />
              <br />
              I’m [Coach's Name], and I’m working with [User’s Name] to support
              their growth through coaching. Thank you for taking the time to
              contribute to their journey by participating in a Comprehensive
              360 Assessment as part of their leadership development.
              <br />
              <br />
              Your feedback will be sent directly to me and will be reviewed
              alongside other responses to help shape [User’s Name]'s overall
              growth plan. All responses will be shared anonymously and will not
              be attributed to you, so please be as honest and candid as
              possible.
              <br />
              <br />
              <span className='text-saffron-700 font-weight-bold'>
                Provide Your Feedback Here
              </span>
              <br />
              <br />
              Your honest and thoughtful feedback is pivotal in shaping an
              effective growth plan for [User’s Name], directly influencing
              their leadership skills and professional development.
              <br />
              <br />
              We truly appreciate your input and the significant role you’re
              playing in their journey.
              <br />
              <br />
              Thank you again for taking the time to provide your insights.
              <br />
              <br />
              Best regards,
              <br />
              [Coach’s Name]
            </div>
          </Col>
        </Row>
      </div>
    </>
  )
}

export default Custom360

const nextIcon = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='16'
      viewBox='0 0 16 16'
      fill='none'>
      <path
        d='M5 14.5859L12 7.79155'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
      />
      <path
        d='M5 1.00249L12 7.79688'
        stroke='white'
        stroke-width='2'
        stroke-linecap='round'
      />
    </svg>
  )
}
