import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import API from '../../utils/API'
import { useErrorService } from '@abroad/components'
import ExportCSV from '../../utils/ExportCSV'

const headers = [
  { label: 'First Name', key: 'fname' },
  { label: 'Last Name', key: 'lname' },
  { label: 'Email', key: 'email' },
  { label: 'Plan Code', key: 'planCode' },
  { label: 'Organization', key: 'company' },
  { label: 'Network', key: 'affiliation' },
  { label: 'Promo Code', key: 'promoCode' },
  { label: 'Created At', key: 'createdAt' },
  { label: 'Survey Taken', key: 'surveyTaken' },
  { label: 'Work Status', key: 'workStatus' },
]

const ExportUser = ({ hasUsersData, showAddUserBtn = false }) => {
  const {
    values: {
      filterBySearch,
      filterByAffiliation,
      filterByCompany,
      filterByPromoCode,
      filterByPlan,
      //filterByCoachId,
    },
  } = useFormikContext()
  const [isLoading, setIsLoading] = useState(false)
  const [exportData, setExportData] = useState([])
  const Error = useErrorService()

  const handleExportUser = async () => {
    setIsLoading(true)

    const params = {
      company: filterByCompany,
      affiliation: filterByAffiliation,
      promoCode: filterByPromoCode,
      planCode: filterByPlan ? JSON.parse(filterByPlan).value : '',
      search: filterBySearch,
      //coachId: filterByCoachId,
    }

    Object.keys(params).forEach(
      (key) => params[key] === '' && delete params[key],
    )

    try {
      const { data: userData } = await API.AdminAPI.userList.exportUserCSV(
        params,
      )
      if (userData) {
        setExportData(userData)
        document.getElementById('EXPORT_USER').click()
        setIsLoading(false)
      }
    } catch (e) {
      setIsLoading(false)
      Error.showError(e)
    }
  }

  return (
    <>
      {(filterByAffiliation !== '' ||
        filterByCompany !== '' ||
        filterByPlan !== '' ||
        filterBySearch !== '' ||
        filterByPromoCode !== '') && (
        <ExportCSV
          isLoading={isLoading}
          headers={headers}
          hasData={hasUsersData}
          exportData={exportData}
          csvId='EXPORT_USER'
          buttonName='EXPORT USER'
          filename='users.csv'
          handleExportUser={handleExportUser}
        />
      )}
    </>
  )
}

export default ExportUser
