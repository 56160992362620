import React from 'react'
import { Container, Row, Col, Table } from 'react-bootstrap'
import {
  WisdomSummaryResult,
  ABSpinner,
  ModalProvider,
  CategoryModal,
} from '@abroad/components'

const WisdomAssessmentResult = ({
  isLoading = false,
  showGoBackButton = false,
  onGoBack,
  headerText,
  subHeaderText,
  subHeaderDescription,
  survey,
}) => {
  const renderGoBackIcon = () => {
    return (
      <div className='back-btn mr-lg-4 mr-2'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='20'
          height='36'
          viewBox='0 0 20 36'
          fill='none'>
          <path
            d='M18.5748 1.35352L1.4248 17.9997'
            stroke='black'
            strokeWidth='2.5'
            strokeLinecap='round'
          />
          <path
            d='M18.5748 34.6462L1.4248 18'
            stroke='black'
            strokeWidth='2.5'
            strokeLinecap='round'
          />
        </svg>

        <svg
          onClick={onGoBack}
          className='cursor-pointer'
          width='20'
          height='36'
          viewBox='0 0 22 38'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'>
          <path
            d='M1.5131 20.4459C0.68878 19.6582 0.688778 18.3418 1.5131 17.5541L18.3682 1.44705C19.6407 0.231097 21.75 1.13298 21.75 2.89299L21.75 35.107C21.75 36.867 19.6407 37.7689 18.3682 36.553L1.5131 20.4459Z'
            fill='black'
          />
        </svg>
      </div>
    )
  }

  if (isLoading) {
    return <ABSpinner />
  }
  return (
    <main>
      <section className='custom-offset-lg-1 custom-col-lg-12 px-lg-0 abroad-fs-exclude wisdom-assessment'>
        <ModalProvider>
          <Container>
            <Row className='mb-3'>
              <Col className='text-center'>
                <div className='d-flex justify-content-center align-items-center'>
                  {showGoBackButton && <>{renderGoBackIcon()}</>}
                  <div className='d-flex'>
                    <h2 className='font-montserrat font-bold font-32 text-dark-900 line-height-39 letter-spacing-minus-02 mb-0'>
                      {headerText}
                    </h2>
                  </div>
                </div>
                {subHeaderText && (
                  <React.Fragment>
                    <h4 className='font-montserrat font-semibold text-grey-600 mt-3'>
                      {subHeaderText}
                    </h4>
                    <p className='font-montserrat text-grey-600 d-flex justify-content-center mt-3'>
                      <span className='w-100 w-lg-7/12'>
                        {subHeaderDescription}
                      </span>
                    </p>
                  </React.Fragment>
                )}
              </Col>
            </Row>
          </Container>
          <WisdomSummaryResult survey={survey} />
          <div>
            <Row className='mb-3'>
              <Col>
                <h3 className='s2 mb-0'>Assessment Users</h3>
              </Col>
            </Row>
            <Table hover responsive className='custom-table'>
              <thead>
                <tr>
                  <th width='5%'>#</th>
                  <th width='10%'>Full Name</th>
                  <th width='20%'>Email</th>
                </tr>
              </thead>
              <tbody>
                {survey?.users && survey?.users.length > 0 ? (
                  survey?.users.map((user, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{user.fullName}</td>
                      <td>{user.email}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan='3' className='text-center'>
                      Assessment users not available at this moment.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
          <CategoryModal />
        </ModalProvider>
      </section>
    </main>
  )
}

export default WisdomAssessmentResult
